import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {Col, Container, Row, Accordion, Card, Button, Table} from "react-bootstrap";
import {FaQuestionCircle, FaClock, FaDollarSign, FaTimes, FaHandHoldingHeart, FaInfo} from "react-icons/fa";

const ServicesPage = () => (
    <Layout pageInfo={{pageName: "services"}}>
        <SEO title="Services Offered Massages by Jooley Portland Massage Therapy"/>

        <Container>
            <Row>
                <Col lg={true}>
                    <h1><FaHandHoldingHeart/> Pricing</h1>
                    <hr/>
                </Col>
            </Row>
        </Container>

        <Container>
            <Row>
                <Col>


                    <Accordion defaultActiveKey="0">

                        <Accordion.Item eventKey="0">
                        <Card>
                            <Card.Header>
                                <Accordion.Header eventKey="0">
                                    In-home Massage
                                </Accordion.Header>
                            </Card.Header>
                            <Accordion.Body eventKey="0">
                                <Card.Body>
                                    <Row>
                                        <Col lg={true}>

                                            <strong><FaQuestionCircle/> What are the benefits of
                                                massage?</strong><br/><br/>

                                            <p className="font-weight-lighter font-italic">
                                                <ul>
                                                    <li>1. Relaxing: Decreases cortisol levels in the body.</li>
                                                    <li>2. Reduces Stress: Helps with stress relief, energy levels and
                                                        pain.
                                                    </li>
                                                    <li>3. Promotes Muscle Relaxation: Promotes circulation which
                                                        increases nutrients and oxygen
                                                        to
                                                        the damaged tissues.
                                                    </li>
                                                    <li>4. Strengthen the Immune System: High levels of stress make you
                                                        more vulnerable to
                                                        illness
                                                        and injury.
                                                    </li>
                                                </ul>
                                            </p>

                                            <p>
                                                <FaInfo/> Travel fees will apply based on location and time of
                                                appointment.

                                                <br/><br/>
                                                <FaInfo/> A $30 fee will be applied for the setup of mobile massage
                                                table.

                                                <br/><br/>
                                                <FaInfo/> An additional $20 fee will be applied for in-hotel massage fees.
                                            </p>

                                            <Table striped hover variant="dark">
                                                <thead>
                                                <tr>
                                                    <th>Duration</th>
                                                    <th>Price</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td><FaClock/> 45 min</td>
                                                    <td><FaDollarSign/> 90</td>
                                                </tr>
                                                <tr>
                                                    <td><FaClock/> 1 hour</td>
                                                    <td><FaDollarSign/> 110</td>
                                                </tr>
                                                <tr>
                                                    <td><FaClock/> 75 min</td>
                                                    <td><FaDollarSign/> 130</td>
                                                </tr>
                                                <tr>
                                                    <td><FaClock/> 90 min</td>
                                                    <td><FaDollarSign/> 150</td>
                                                </tr>
                                                <tr>
                                                    <td><FaClock/> 2 hours</td>
                                                    <td><FaDollarSign/> 190</td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Col lg={true}>
                                            <div className="joolesm2" />
                                            <br/>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Accordion.Body>
                        </Card>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                        <Card>
                            <Card.Header>
                                <Accordion.Header eventKey="4">
                                    On-site Chair Massage
                                </Accordion.Header>
                            </Card.Header>
                            <Accordion.Body eventKey="4">
                                <Card.Body>
                                    <Row>
                                        <Col lg={true}>

                                            <strong><FaQuestionCircle/> What is On-site Chair
                                                massage?</strong><br/><br/>

                                            <p className="font-weight-lighter font-italic">
                                                <FaInfo/> On-site Chair massage is the opportunity to receive massage
                                                in your place of work or at your event. Providing massage on-site
                                                can boost team morale and offer incentives. During chair massage,
                                                clients
                                                remain clothed. Most on-site massages last between 15 and 30 minutes
                                                where
                                                the therapist focuses on main trouble areas.

                                            </p>

                                            <p>
                                                <FaInfo/> Travel fees will apply based on location.<br/>
                                                1 hour minimum required.<br/>
                                                Length of each appointment is variable based on number of clients
                                                receiving massage
                                                and full block of time scheduled.
                                            </p>

                                            <p>

                                            </p>

                                            <Table striped hover variant="dark">
                                                <thead>
                                                <tr>
                                                    <th>Duration</th>
                                                    <th>Price</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td><FaClock/> Per Hour</td>
                                                    <td><FaDollarSign/> 100</td>
                                                </tr>

                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Accordion.Body>
                        </Card>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                        <Card>
                            <Card.Header>
                                <Accordion.Header eventKey="1">
                                    Qi Gong
                                </Accordion.Header>
                            </Card.Header>
                            <Accordion.Body eventKey="1">
                                <Card.Body>
                                    <Row>
                                        <Col lg={true}>

                                            <p className="font-weight-lighter font-italic">
                                                <FaInfo/> Qi Gong, pronounced(chee-gong) is a method of breathwork
                                                and movements that open the flow of energy in the body's meridians
                                                observed in acupuncture
                                                and Chinese medicine. Physically, Qi Gong warms tendons, ligaments and
                                                muscles while it
                                                tonifies vital organs and connective tissues. Like massage, Qi Gong
                                                promotes circulation
                                                of body fluids such as blood, synovial and lymph.
                                            </p>

                                            <Table striped hover variant="dark">
                                                <thead>
                                                <tr>
                                                    <th>Duration</th>
                                                    <th>Price</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td><FaClock/> 30 min</td>
                                                    <td><FaDollarSign/> 50</td>
                                                </tr>
                                                <tr>
                                                    <td><FaClock/> 45 min</td>
                                                    <td><FaDollarSign/> 70</td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Accordion.Body>
                        </Card>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                        <Card>
                            <Card.Header>
                                <Accordion.Header eventKey="2">
                                    Individual Packages
                                </Accordion.Header>
                            </Card.Header>
                            <Accordion.Body eventKey="2">
                                <Card.Body>
                                    <Row>
                                        <Col lg={true}>

                                            <p>Get a package today to invest in your wellness and save money!</p>

                                            <p className="font-weight-lighter font-italic">
                                                <FaInfo/> Must be used within 6 months.
                                            </p>

                                            <Table striped hover variant="dark">
                                                <thead>
                                                <tr>
                                                    <th>Service & Quantity</th>
                                                    <th>Price</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>5 <FaTimes/> 60 min massage</td>
                                                    <td><FaDollarSign/> 495(10% off)</td>
                                                </tr>
                                                <tr>
                                                    <td> 5 <FaTimes/> 90 min massage</td>
                                                    <td><FaDollarSign/> 675(10% off)</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong> Wellness Package! </strong><br/>
                                                        5 <FaTimes/> 60 min massage<br/>
                                                        5 <FaTimes/> 30 min Qi Gong
                                                    </td>
                                                    <td><FaDollarSign/> 720(10% off)</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h5> ULTIMATE Wellness Package!</h5>
                                                        5 <FaTimes/> 90 min massage<br/>
                                                        5 <FaTimes/> 45 min Qi Gong
                                                    </td>
                                                    <td><FaDollarSign/> 990(10% off)</td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Col lg={true}>
                                            <div className="joolesm3" />
                                            <br/>
                                        </Col>
                                    </Row>

                                </Card.Body>
                            </Accordion.Body>
                        </Card>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                        <Card>
                            <Card.Header>
                                <Accordion.Header eventKey="3">
                                    Guided Couples Massage
                                </Accordion.Header>
                            </Card.Header>
                            <Accordion.Body eventKey="3">
                                <Card.Body>
                                    <Row>
                                        <Col lg={true}>

                                            <p className="font-weight-lighter font-italic">
                                                <FaInfo/> Couples massage can help loved ones improve their own massage
                                                skills to
                                                alleviate daily aches and pains. This course will not provide the
                                                training needed to
                                                massage professionally but will teach the basics of massage techniques
                                                and effective communication between giver and receiver
                                                of the massage. Each couple's guidance plan will be custom tailored to
                                                their needs and progress with one another.
                                            </p>

                                            <Table striped hover variant="dark">
                                                <thead>
                                                <tr>
                                                    <th>Duration</th>
                                                    <th>Price</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td><FaClock/> 2 hours</td>
                                                    <td><FaDollarSign/> 350</td>
                                                </tr>
                                                <tr>
                                                    <td><FaClock/> 1 hour follow-up</td>
                                                    <td><FaDollarSign/> 150</td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Accordion.Body>
                        </Card>
                        </Accordion.Item>
                    </Accordion>
                    <br/>

                </Col>
            </Row>
        </Container>

        <Container>
            <Row>
                <Col lg={true}>
                    <h2>Gift Certificates Available!</h2>
                </Col>
            </Row>
        </Container>

    </Layout>
);

export default ServicesPage
